import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const MandatoryDisclosure = () => { 
    useEffect(() => {
        document.title = 'Mandatory Disclosure - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>   
                    <li>Mandatory Disclosure</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Mandatory Disclosure</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <div class="col-lg-12">
                        <h6>GENERAL INFORMATION</h6>
                        <div class="table-responsive maintable">
                            <table class="table">
                                <thead>
                                   <tr><th width="2%;">S.No.</th><th>INFORMATION</th><th>DETAILS</th></tr>
                               </thead>
                               <tbody>
                        <tr><td>1</td>
                         <td>NAME OF THE SCHOOL </td>
                         <td>Jai Rani SABS Public School</td>
                         </tr>
                    <tr><td>2</td>
                     <td>AFFILIATION NO.(IF APPLICABLE)</td>
                     <td> </td>
                     </tr>
                    <tr><td>3</td>
                     <td>SCHOOL CODE (IF APPLICABLE)</td>
                     <td></td>
                     </tr>
                    <tr><td>4</td>
                     <td>COMPLETE ADDRESS WITH PIN CODE</td>
                     <td>Balussery Panangad Rd, Balussery, Kerala 673612</td>
                     </tr>
                    <tr><td>5</td>
                     <td>PRINCIPAL NAME &  QUALIFICATION: </td>
                     <td>Sr.Philomina Thomas</td>
                     </tr>
                    <tr><td>6</td>
                     <td>SCHOOL EMAIL ID </td>
                     <td>-</td>
                     </tr>
                    <tr><td>7</td>
                     <td>CONTACT DETAILS (LANDLINE/MOBILE)</td>
                     <td>Phone No : 8089147954 </td>
                     </tr></tbody></table></div><h6>DOCUMENTS AND INFROMATION</h6><div class="table-responsive maintable"><table class="table"><thead>
                        <tr><th width="2%;">S.No.</th><th>DOCUMENTS/INFORMATION</th><th>VIEW</th></tr></thead><tbody>
                        <tr><td>1</td>
                         <td>COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY </td>
                         <td><a class="a1" href="https://webapi.entab.info/api/image/JRPSB/public/pdf/Affliation.pdf" target="_blank"> VIEW </a></td>
                         </tr>
                    <tr><td>2</td>
                     <td>COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE</td>
                     <td><a href="#" target="_blank"> VIEW </a></td>
                     </tr>
                    <tr><td>3</td>
                     <td>COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT</td>
                     <td><a href="#" target="_blank"> VIEW </a></td>
                     </tr>
                    <tr><td>4</td>
                     <td>COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE</td>
                     <td><a href="#" target="_blank"> VIEW </a></td>
                     </tr>
                    <tr><td>5</td>
                     <td>COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE</td>
                     <td><a href="#" target="_blank"> VIEW </a></td>
                     </tr>
                    <tr><td>6</td>
                     <td>COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY</td>
                     <td><a href="#" target="_blank"> VIEW </a></td>
                     </tr>
                    <tr><td>7</td>
                     <td>COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES</td>
                      <td><a href="#" target="_blank"> VIEW </a></td>
                     </tr></tbody></table></div><h6>DOCUMENTS AND INFROMATION</h6><div class="table-responsive maintable"><table class="table"><thead>
                        <tr><th width="2%;">S.No.</th><th>DOCUMENTS/INFORMATION</th><th>VIEW</th></tr></thead><tbody>
                        <tr><td>1</td>
                         <td>FEE STRUCTURE OF THE SCHOOL</td>
                          <td><a href="#" target="_blank"> VIEW </a></td>
                         </tr>
                    <tr><td>2</td>
                     <td>ANNUAL ACADEMIC CALANDER.</td>
                     <td><a href="#" target="_blank"> VIEW </a></td>
                     </tr>
                    <tr><td>3</td>
                     <td>LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)</td>
                     <td><a class="a1" href="https://webapi.entab.info/api/image/JRPSB/public/pdf/SMC.pdf" target="_blank"> VIEW </a></td>
                     </tr>
                    <tr><td>4</td>
                     <td>LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS</td>
                     <td><a href="#" target="_blank"> VIEW </a></td>
                     </tr>
                    <tr><td>4</td>
                     <td>LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABLILITY</td>
                     <td><a href="#" target="_blank"> VIEW </a></td>
                     </tr>
                    <tr><td>5</td>
                     <td>SELF DECLARATION</td>
                     <td><a href="#" target="_blank"> VIEW </a></td>
                     </tr></tbody></table></div><h6>RESULT CLASS XII</h6><div class="table-responsive maintable"><table class="table"><thead>
                        <tr><th width="2%;">S.No.</th><th>YEAR</th><th>NO. OF REGISTERED STUDENTS</th><th>NO. OF STUDENTS PASSED</th><th>PASS PERCENTAGE</th></tr></thead><tbody>
                        <tr><td>1</td>
                         <td>2024</td>
                         <td>35</td>
                         <td>35</td>
                         <td>100 %</td>
                         </tr>
                     <tr><td>2</td>
                     <td>2023</td>
                     <td>29</td>
                     <td>29</td>
                     <td>100 %</td>
                     </tr>
                    <tr><td>3</td>
                     <td>2022</td>
                     <td>35</td>
                     <td>35</td>
                     <td>100 %</td>
                     </tr>
                    </tbody></table></div><h6>STAFF (TEACHING)</h6><div class="table-responsive maintable"><table class="table"><thead>
                        <tr><th width="2%;">S.No.</th><th>INFORMATION</th><th>DETAILS</th></tr></thead><tbody>
                        <tr><td>1</td>
                         <td>PRINCIPAL</td>
                         <td>1</td>
                         </tr><tr rowspan="6"><td>2 </td>
                         <td>TOTAL NO. OF TEACHERS</td>
                         <td> -</td>
                         </tr>
                    <tr><td></td>
                        <td>PGT  </td>
                     <td>-</td>
                     </tr>
                    <tr><td> </td>
                     <td>TGT </td>
                     <td>-</td>
                     </tr>
                    <tr><td> </td>
                     <td>PRT  </td>
                     <td>-</td>
                     </tr>
                    <tr><td> </td>
                     <td>NTT  </td>
                     <td>-</td>
                     </tr>
                    <tr><td> </td>
                     <td> PET  </td>
                     <td>-</td>
                     </tr>
                    <tr><td> </td>
                     <td>NON TEACHING  </td>
                     <td>-</td>
                     </tr>
                    <tr><td>3</td>
                     <td>TEACHERS SECTION RATIO</td>
                     <td>-</td>
                     </tr>
                    <tr><td>4</td>
                     <td>DETAILS OF SPECIAL EDUCATOR</td>
                     <td>-</td>
                     </tr>
                    <tr><td>5</td>
                     <td>DETAILS OF COUNSELLOR AND WELNESS TEACHER</td>
                     <td>-</td>
                     </tr>
                    </tbody>
                    </table>
                    </div>
                    <h6>SCHOOL INFRASTRUCTURE</h6>
                    <div class="table-responsive maintable">
                        <table class="table">
                            <thead>
                        <tr><th width="2%;">S.No.</th><th>INFORMATION</th><th>DETAILS</th></tr>
                    </thead>
                    <tbody>
                        <tr><td>1</td>
                         <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)</td>
                         <td>-</td>
                         </tr>
                    <tr><td>2 </td>
                     <td>NO. AND SIZE OF THE CLASS ROOMS (IN SQ FTMTR)</td>
                     <td>-</td>
                     </tr>
                    <tr><td>3</td>
                     <td>NO. AND SIZE OF LABORATORIES INCLUDING  COMPUTER  LABS (IN SQ MTR)</td>
                     <td>-</td>
                     </tr>
                    <tr><td>4</td>
                     <td>INTERNET FACILITY (Y/N)</td>
                     <td>-</td>
                     </tr>
                    <tr><td>5</td>
                     <td>NO. OF GIRLS TOILETS</td>
                     <td>-</td>
                     </tr>
                    <tr><td>6</td>
                     <td>NO. OF BOYS TOILETS</td>
                     <td>-</td>
                     </tr>
                    <tr><td>7</td>
                     <td>LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL</td>
                     <td><a class="a1" href="#" target="_blank"> YOUTUBE </a></td>
                     </tr>
                    </tbody>
                    </table>
                    </div>
                    </div>
                        </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default MandatoryDisclosure;
