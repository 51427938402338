import { useEffect, useState } from "react";
import Slider from "react-slick";
import { getBirthdays } from '../Service/Api';
const Birthday = () => { 
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState(null);

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

useEffect(() => {
       const fetchData = async () =>{
              try{
                 const birthdayData = await getBirthdays();
                 setData(birthdayData) ;  
                 console.log(birthdayData)
              }catch(error){
               setError(error);
              }finally{
               setLoading(false)
              }
       }
       fetchData();
   },[]);
   const emptyArray = [
    {
      id:1, 
    } ,
    {
      id:2, 
    } 
    
    // Other entries...
  ];
  const settings = {
    autoplay: true,
    infinite: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1, 
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1, 
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1, 
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, 
        }
      }
    ]
  };


    return (
        <>
        <Slider {...settings}>
        
        {data && data.length > 0 ? (
          data.map((item, index) => {
            const date = new Date();
            const day = date.getDate(); 
            const month = date.getMonth();  
            const mymonth = monthNames[month]; 
        return (
        <div className="item" key={index}>
          <div className="topperdiv birthday-bg">
            <div className="topperimages">
                {item.attachments.length > 0 ? ( <img src={`https://webapi.entab.info/api/image/${item?.attachments}`} className="img-fluid tooperpicicn" />) :
                   <img src="https://webapi.entab.info/api/image/JRPSB/public/Images/trophy.png" className="img-fluid tooperpicicn" />
                }
              <p> {day} {mymonth}</p>
            </div>
            <div className="topperdesc">
              <h5>{item.name}</h5>
              <p className="per">{item.class}</p>
            </div>
          </div>
        </div> 
        )
      })
    ) : (
      emptyArray.map((item) => (
        <div className="item" key={item.id}>
        <div className="topperdiv birthday-bg">
          <div className="topperimages">
            <img src="https://webapi.entab.info/api/image/JRPSB/public/Images/trophy.png" className="img-fluid tooperpicicn" />
            <p>DD MM</p>
          </div>
          <div className="topperdesc">
            <h5>Student name</h5>
            <p className="per">Class</p>
          </div>
        </div>
      </div> 
        ))
        )}
      </Slider>

        </>
    )
}
export default Birthday