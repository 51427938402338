import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { getEvents } from '../Service/Api'
const Events = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventData = await getEvents();
        setData(eventData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false)
      }
    }
    fetchData();
  }, []);

  const emptyArray = [
    {id:1}, 
    {id:2},
    {id:3},
    {id:4},
  ]
  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <>
      <div className="row">
        <Slider {...settings}>
            {data && data.length > 0 ? (data.map((item,index) =>  {
              const date = new Date(item.date);
              const mon = monthNames[date.getMonth()];
                const eventdate = date.getDate();  
              return  ( 
              <div className="item" key={index}>
              <div className="homeeventbox">
                <div className="homeeventboximg">
                <div className="homeeventboxdate">{eventdate} {mon}</div>
                     {item.images.length> 0 ? ( <img src={`https://webapi.entab.info/api/image/${item.images}`} className="img-fluid" alt="Jai Rani public School" />  ) : (<img src="https://webapi.entab.info/api/image/JRPSB/public/Images/events.jpg" className="img-fluid" alt="Jai Rani public School" />)}
               
                </div>
                <div className="homeeventboxdesc">
                <h5>{item.title}</h5>
                  <p>{item.description}</p>
  
                  <div className="homeeventboxbottom eventbotm">
                  <span><i className="bi bi-geo-alt-fill"></i>{item.venue}</span>
                    <span><i className="bi bi-clock-fill"></i>{item.time} </span>
                  </div>
                </div>
              </div>
            </div>

            )})) : 
   emptyArray.map((item) => (
              <div className="item" key={item.id}>
              <div className="homeeventbox">
                <div className="homeeventboximg">
                   <div className="homeeventboxdate">DD MM</div>
                      <img src="https://webapi.entab.info/api/image/JRPSB/public/Images/events.jpg" className="img-fluid" alt="Jai Rani public School" /> 
                </div>
                <div className="homeeventboxdesc">
                   <h5> Event Title</h5>
                   <p> Event Description</p>
                  <div className="homeeventboxbottom">
                  <span><i className="bi bi-geo-alt-fill"></i>School Campus</span>
                    <span><i className="bi bi-clock-fill"></i>00:00 </span>
                  </div>
                </div>
              </div>
            </div>
            ))
     }
        </Slider>
        <Link to="/Events"><button className='read-more'>READ MORE</button></Link>
      </div>
    </>
  )
}

export default Events