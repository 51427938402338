import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const CabinetLeaders = () => { 
    useEffect(() => {
        document.title = 'Cabinet Leaders - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>About </li>
                    <li>Cabinet Leaders</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Cabinet Leaders</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <img src="https://webapi.entab.info/api/image/JRPSB/public/Images/cabinetleader.jpg" className="img-fluid mx-auto d-block" alt=" Jai Rani Public School, Balussery" />
                        </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default CabinetLeaders;
