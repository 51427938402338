import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const PlayGround = () => { 
    useEffect(() => {
        document.title = 'Play Ground - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>Facilities </li>
                    <li>Play Ground</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Play Ground</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <p> At Jai Rani SABS Public School, our playground transcends recreation, serving as a vibrant hub for growth, learning, and development. Designed to foster holistic growth, our playground offers opportunities for students to refine essential skills, forge strong relationships, and cultivate physical and mental well-being. </p>

                        <p>As an integral part of our curriculum, our playground supports learning objectives and reinforces classroom lessons. By integrating physical activity, social interaction, and creative play, we create a dynamic learning environment that prepares students for success in all aspects of life. </p>
                        </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default PlayGround;
