import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { getGallery } from '../Service/Api'
const Gallery = () => { 
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getGallery();
        setData(galleryData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3, 
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, 
        }
      }
    ]
  };
  const emptyArray = [
    {id:1}, 
    {id:2},
    {id:3},
    {id:4},
  ]
  return (
    <>
      <div className="row">
      <Slider {...settings}>
      {data.length > 0 ? (data.map((item,index) =>   ( 
          <div className="item" key={index}>
          <div className="gallerysec">
              <div className="gallerysecimg">
                <img
                src={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                alt={item.title} // Use item title as alt text for better accessibility
                className="img-fluid"
              />
                <h3>{item.title}</h3>
              </div>
              <button><Link to="Gallery">View Gallery</Link></button>
            </div>
        </div> 

      ))) : 
emptyArray.map((item) => (
  <div className="item" key={item.id}>
 <div className="gallerysec">
              <div className="gallerysecimg">
                <img
                src="https://webapi.entab.info/api/image/JRPSB/public/Images/gal-1.jpg"
                alt={item.title} // Use item title as alt text for better accessibility
                className="img-fluid"
              />
                <h3>Gallery Title</h3>
              </div>
              <button><Link to="Gallery">View Gallery</Link></button>
            </div>
</div>
      ))
}
      {data.length >= 4 &&
        data.map((item, index) => ( 
          <div className="item" key={index}>
            <div className="gallerysec">
              <div className="gallerysecimg">
                <img
                src={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                alt={item.title} // Use item title as alt text for better accessibility
                className="img-fluid"
              />
                <h3>{item.title}</h3>
              </div>
              <button><Link to="Gallery">View Gallery</Link></button>
            </div>
          </div>
        ))}
        {data.length < 4 &&
          data.length > 0 &&
          data.map((item, index) =>
            item.attachments.map((img) => (
             <div className="gallerysec">
              <div className="gallerysecimg">
                <img
                src={`https://webapi.entab.info/api/image/${img}`}
                alt={item.title} // Use item title as alt text for better accessibility
                className="img-fluid"
              />
                <h3>{item.title}</h3>
              </div>
              <button><Link to="Gallery">View Gallery</Link></button>
            </div>
            ))
          )}
          
      </Slider>
      </div>
    </>
  )
}

export default Gallery