import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const VisionMission = () => { 
    useEffect(() => {
        document.title = 'Vision and Mission - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>About </li>
                    <li>Vision & Mission</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Vision & Mission</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h3>Vision</h3>
                             <ul className="list">
                             <li> Developing a community service ethic and practice in all students.</li>
                             <li> Ensuring  caring and family atmosphere in our school.</li>
                             <li> The practice of an open, transparent and collaborative decision-making process.</li>
                             <li> Reducing our school’s environmental footprint.</li>
                             </ul>
                            <h3>Mission</h3>
                           <p>Jai Rani SABS Public School serves students from Balussery and nearby locality with quality education that enables students to be inspired learners and responsible global citizens through the collaboration of a dedicated faculty and a supportive community. We mould our students to be internationally minded, culturally sensitive, confident and critical thinkers. They aspire to join leading universities and to become leaders in their fields. We support the globally mobile population by offering high class education by imparting values.</p>
                         </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default VisionMission;
