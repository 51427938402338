import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const CoCurricularActivities = () => { 
    useEffect(() => {
        document.title = 'Co-Curricular Activities - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>Facilities </li>
                    <li>Co-Curricular Activities</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Co-Curricular Activities</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <p><b>Jai Rani SABS Public School </b> has been designed to go beyond the confines of textbooks, and our commitment to holistic development is evident in the array of co-curricular activities we offer to our students. At Jai Rani, we firmly believe that a well-rounded education is essential for nurturing young minds, and our diverse range of activities reflects this philosophy. </p>

                        <p><b>Skating: </b> Skating not only promotes physical fitness but also teaches discipline and balance. Our state-of-the-art skating facilities provide students with a fun and engaging way to stay active and healthy. </p>
                    
                        <p><b>Jiu-Jitsu: </b> Jiu-Jitsu at Jai Rani School is more than just a martial art; it's a way of life. Rooted in discipline, respect, and self-defense, our Jiu-Jitsu program empowers students with practical techniques while instilling confidence and resilience. Under the guidance of experienced instructors, students learn the principles of leverage, timing, and control, fostering physical fitness, mental focus, and emotional well-being. </p>
                    
                        <p><b>Karate: </b> Karate School is an ancient discipline that combines physical prowess with mental discipline. Through rigorous training and traditional forms, students develop strength, agility, and self-awareness. Our karate program emphasizes the importance of respect, perseverance, and self-control, guiding students on a journey of self-discovery and personal growth. Whether it's mastering basic stances or advancing through belt ranks, students of all ages and abilities find fulfilment and purpose in our karate classes. </p>
                       
                        <p><b>Art and Craft: </b> Unlocking creativity is a key aspect of our curriculum. Our art and craft activities encourage self-expression, imagination, and the development of fine motor skills. Students have the opportunity to explore various mediums and unleash their artistic potential. </p>
                    
                        <p><b>Abacus: </b> The abacus program at Jai Rani enhances mathematical skills and mental agility. Through this activity, students develop a strong foundation in arithmetic and problem-solving, setting the stage for academic success. </p>
                    
                        <p><b>Chess: </b> Chess is more than just a game; it's a cognitive exercise that enhances strategic thinking, concentration, and decision-making skills. Our chess program fosters intellectual development and sportsmanship. </p>
                    
                        <p><b>Music: </b> At Jai Rani, we offer a variety of musical activities including Guitar, Violin, Keyboard, and general Music classes. These activities not only allow students to discover and hone their musical talents but also contribute to their cognitive development and emotional expression. </p>
                         <p><b>Drawing: </b> The art of drawing is celebrated at Jai Rani, providing students with a platform to express themselves visually. Whether it's pencil sketches or vibrant paintings, our drawing program encourages creativity and an appreciation for aesthetics. </p>
                         <p><b> Guitar: </b> The Guitar program offers students the opportunity to explore the world of music through one of the most versatile and popular instruments. From learning basic chords to mastering intricate melodies, our guitar classes cater to students of all skill levels. Through individualized instruction and group sessions, students develop their musical talents, enhance their coordination, and cultivate a lifelong appreciation for music. </p>
                        <p><b>Violin: </b> The Violin program introduces students to the classical and contemporary repertoire of this elegant instrument. Through structured lessons and ensemble performances, students learn proper technique, musical interpretation, and the importance of ensemble playing. The violin program not only nurtures artistic expression but also promotes discipline, concentration, and self-confidence among students. </p>
                        <p><b> Keyboard: </b> The Keyboard program provides students with a comprehensive understanding of piano techniques and music theory. Whether beginners or advanced players, students receive personalized instruction to develop their keyboard skills, sight-reading abilities, and musical interpretation. Through a diverse repertoire ranging from classical to contemporary music, students explore the rich world of keyboard performance and composition. </p>
                         <p><b>Classical Music: </b> Classical music is a rich and diverse genre that encompasses a vast array of compositions spanning centuries of musical history. Our classical music program celebrates the timeless beauty and sophistication of this art form, offering students a unique opportunity to explore its depth and complexity. </p>
                         <p><b>Dance: </b> Dance is an expressive art form that celebrates movement, rhythm, and creativity. Our dance program offers students a vibrant and engaging platform to explore various dance styles, develop their skills, and express themselves artistically. </p>
                         <p><b>Band: </b> Our school band is a dynamic and vibrant community where students come together to explore the world of music, develop their instrumental skills, and experience the joy of ensemble performance. Our band program offers a comprehensive musical education that encompasses a wide range of instruments and genres, catering to students of all interests and skill levels. </p>
                        <p> These co-curricular activities are an integral part of our educational approach at Jai Rani SABS Public School. They complement the academic curriculum by fostering teamwork, discipline, leadership skills, and a sense of responsibility. We are proud to provide our students with a well-rounded education that prepares them not only for academic success but also for the challenges and opportunities they will encounter in the broader world. </p>
                        </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default CoCurricularActivities;
